import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import './mystyles.scss'

const Carousel = ({
  title,
  subtitle,
  color,
  img,
  imgalt,
  size,
  xclass,
  button,
  bclass,
}) => (
  <section className={`hero ${size} is-bold ${color} has-background ${xclass}`}>
    <img
      alt={`${imgalt}`}
      className="hero-background is-transparent"
      src={`${img}`}
    />
    <div className="hero-body">
      <div className="container">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        {button && (
          <Link to="/" aria-label="back">
            <div className={`${bclass}`}>{button}</div>
          </Link>
        )}
      </div>
    </div>
  </section>
)

Carousel.propTypes = {
  siteTitle: PropTypes.string,
}

Carousel.defaultProps = {
  button: ``,
}

export default Carousel
