import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Trigger } from './common'
import './mystyles.scss'

const NavbarItem = (props) => (
  <Link className="navbar-item is-capitalized" to={`${props.pagelink}`}>
    {props.page}
  </Link>
)
const NavbarBurger = (props) => (
  <button
    onClick={props.toggleMenu}
    className={`header--navbar button is-white navbar-burger ${
      props.active ? 'is-active' : ''
    }`}
    id="navbar-burgerid"
    aria-label="menu"
    aria-expanded="false"
  >
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </button>
)

class Header extends React.Component {
  state = {
    activeMenu: false,
  }
  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    })
  }

  render() {
    const pageArray = ['/about', '/course', '/news', '/contact']
    const NavbarItems = pageArray.map((p, index) => {
      return (
        <NavbarItem
          page={<FormattedMessage id={`navbar-${index + 1}`} />}
          pagelink={p}
          key={index}
        />
      )
    })
    return (
      <StaticQuery
        query={graphql`
          {
            logo: file(relativePath: { eq: "smartnative-icon.png" }) {
              childImageSharp {
                gatsbyImageData(width: 30, height: 30, layout: FIXED)
              }
            }
          }
        `}
        render={(data) => (
          <header>
            <nav className="header--navbar navbar is-white is-fixed-top">
              <div className="navbar-brand">
                <div className="navbar-item">
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    className="logo"
                    alt="Smart Native logo"
                  />
                </div>
                <NavbarItem page="Smart Native" pagelink="/" />
                <NavbarBurger
                  active={this.state.activeMenu}
                  toggleMenu={this.toggleMenu}
                />
              </div>
              <div
                className={`navbar-menu ${
                  this.state.activeMenu ? 'is-active' : ''
                }`}
              >
                <div className="navbar-start">{NavbarItems}</div>
                <div className="navbar-end">
                  <div className="navbar-item">
                    <Trigger />
                  </div>
                </div>
              </div>
            </nav>
          </header>
        )}
      />
    )
  }
}

export default Header
