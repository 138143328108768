/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import './mystyles.scss'

import './layout.css'

import { IntlProvider } from 'react-intl'
import localEng from '../messages/en.json'
import localZh from '../messages/zh.json'
import { Context } from './common/Context'
import Provider from './common/Layouts/Provider'
// const pages = ["about", "course", "news", "contact"]

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Provider>
        <Context.Consumer>
          {({ lang }) => (
            <IntlProvider
              locale={lang}
              messages={lang === 'en' ? localEng : localZh}
            >
              <Header />
              <div
                style={{
                  margin: `3em auto`, //margin
                  // maxWidth: 960,
                  // padding: `0px 1.0875rem 1.45rem`,
                  paddingTop: 0,
                }}
              >
                <main>{children}</main>
              </div>
              <Footer siteTitle={data.site.siteMetadata.title} />
              <script
                src="//code.tidio.co/eco7ii6f8t7dbkwrwoqdbggttirrxk5h.js"
                async
              ></script>
            </IntlProvider>
          )}
        </Context.Consumer>
      </Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
