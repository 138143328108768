import React from 'react'
import { FormattedMessage } from 'react-intl'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import './mystyles.scss'

const Footer = ({ data }) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      className="footer-chart"
    >
      <path
        // className="footer-chart"
        fill="#0099ff"
        fillOpacity="1"
        d="M0,224L60,197.3C120,171,240,117,360,101.3C480,85,600,107,720,144C840,181,960,235,1080,250.7C1200,267,1320,245,1380,234.7L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
      ></path>
    </svg>
    <footer className="site-footer">
      <div className="container">
        <div className="site-footer-inner">
          <div className="brand footer-brand">
            <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              className="footer--logo"
              alt="Smart Native logo"
            />
          </div>
          <ul className="footer-links list-reset">
            {/* <li>Learn with the native.</li> */}
            <li>
              <strong>
                <FormattedMessage id="footer--contact1" />:
              </strong>{' '}
              (852) 3996 8188{' '}
            </li>
            <li>
              <strong>
                <FormattedMessage id="footer--contact2" />:
              </strong>{' '}
              info@smartnative.hk
            </li>
          </ul>
          <ul className="footer-links list-reset">
            <li>
              <strong>
                <FormattedMessage id="footer--contact3" />:
              </strong>{' '}
              <FormattedMessage id="footer--contact3-content" />
            </li>
          </ul>
          <ul className="footer-social-links list-reset">
            <li>
              <a
                href="https://www.facebook.com/Smart-Native-English-Learning-Center-1888301908127337/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <svg
                  className="footer--social"
                  width="50"
                  height="50"
                  viewBox="0 0 512 512"
                  preserveAspectRatio="xMidYMid meet"
                  alt="Facebook"
                >
                  <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"></path>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCysILA4A51MX9RDpydDg5jw"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Youtube"
              >
                <svg
                  className="footer--social"
                  width="50"
                  height="50"
                  viewBox="0 0 512 512"
                  preserveAspectRatio="xMidYMid meet"
                  alt="YouTube"
                >
                  <path d="M422.6 193.6c-5.3-45.3-23.3-51.6-59-54 -50.8-3.5-164.3-3.5-215.1 0 -35.7 2.4-53.7 8.7-59 54 -4 33.6-4 91.1 0 124.8 5.3 45.3 23.3 51.6 59 54 50.9 3.5 164.3 3.5 215.1 0 35.7-2.4 53.7-8.7 59-54C426.6 284.8 426.6 227.3 422.6 193.6zM222.2 303.4v-94.6l90.7 47.3L222.2 303.4z"></path>
                </svg>
              </a>
            </li>
          </ul>
          <div className="footer-copyright">
            <FormattedMessage id="footer--copyright-1" />© Smart Native English
            Learning Center {new Date().getFullYear()}{' '}
            <FormattedMessage id="footer--copyright-2" />
          </div>
        </div>
      </div>
    </footer>
  </div>
)

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        logo: file(relativePath: { eq: "smartnative-icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 45, height: 45, layout: FIXED)
          }
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
)
