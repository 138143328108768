import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Context } from '../Context'

const Trigger = () => (
  <Context.Consumer>
    {({ toggleLanguage }) => (
      <button type="button" className="navbar-button" onClick={toggleLanguage}>
        <svg id="icon-translate" viewBox="0 0 32 32" className="about--icon">
          <title>translate</title>
          <path d="M11.856 14.4l3.584 3.584-1.328 3.2-4.512-4.544-5.28 5.28-2.24-2.272 5.248-5.248-1.408-1.408c-0.848-0.848-1.6-2.080-2.080-3.392h3.52c0.24 0.448 0.528 0.848 0.816 1.12l1.424 1.44 1.408-1.408c0.96-0.992 1.792-3.008 1.792-4.352h-12.8v-3.2h8v-3.2h3.2v3.2h8v3.2h-3.2c0 2.192-1.184 5.040-2.72 6.592l-1.44 1.408zM18 27.2l-2 4.8h-3.2l8-19.2h3.2l8 19.2h-3.2l-2-4.8h-8.8zM19.328 24h6.144l-3.072-7.36-3.072 7.36z"></path>
        </svg>
      </button>
    )}
  </Context.Consumer>
)

export { Trigger }
